import React from "react";
import styles from "./Certificate.module.scss";
export default function Certificate() {
  const certify = [
    {
      img:"assets/certify/deploma.jpeg",
      name: "A.T.C.L (Music Performance)",
      para: "Trinity’s performance diplomas open a window for you to pursue professional musicianship. Focusing exclusively on the planning and performance of a recital, these courses will help you learn to play the instruments in a correct manner and perform confidently with grace. The A.T.C.L  is equivalent in standard to the first year recital of an undergraduate degree. Learn the basics of playing classical instruments like violin, piano and classical guitar. ",
    },
    {
      img:"assets/certify/deploma_2.jpeg",
      name: "L.T.C.L ( Music Performance)",
      para: "Work towards your goal of achieving a professional musicianship, with LTCL which is  equivalent in standard to the first year recital of an undergraduate degree. Build a programme you can be confident in with the knowledge of classical music you have gained and create a programme that reflects the musician in you. Our marking scheme is heavily weighted towards performance, you must make sure your exam performance holds value and gains a respected qualification that genuinely reflects the standard of your performance. ",
    },
    {
      img:"assets/certify/deploma_3.jpeg",
      name: "F.T.C.L (Music Performance)",
      para: "Equivalent in standard to a postgraduate course recital at a conservatoire or university Performance focus. In the course the  marks are awarded mostly for the recital, However for F.T.C.L  diplomas detailed programme notes are required.",
    },
  
  ];

  const pop = [
    {
      img:"assets/certify/deploma.jpeg",
      name: "A.T.C.L  (Music Performance)",
      para: "ATCL is equivalent in difficulty to the first year of an undergraduate degree. Here, the candidates will be equipped with the playing techniques of various musical instruments that make the backbone of rock and pop, like keyboard, drums and plectrum guitar. Enjoy the freedom of choosing pieces you are curious about from our newly expanded and regularly updated repertoire lists curated by our trusted teachers and candidates over the years. Furthermore, you can submit your own-choice pieces (or a mixture of both). ",
    },
    {
      img:"assets/bannr/banner_1.webp",
      name: "L.T.C.L (Music Performance) ",
      para: "As the  L.T.C.L is  equivalent to the final year of an undergraduate degree, the candidates have a professional sense of performance and musical understanding. This degree leads them to become true musicians, one that can compose, arrange and perform their recital like an artist.",
    },
  ];

  const theory = [
    {
      img:"assets/certify/theory.jpeg",
      name: "AMusTCL (Music theory) ",
      para: "Trinity’s music theory diplomas focus on theoretical and analytical concepts and procedures, requiring extensive knowledge of music history in the Western classical tradition. AMusTCL is equivalent in standard to the first year of an undergraduate degree, it  helps candidates understand the theory behind effective music teaching, and their ability to plan and deliver activities.",
    },
    {
      img:"assets/certify/theory_2.jpeg",
      name: "LMusTCL (Music Theory)",
      para: "The course of  study in Music Theory includes the areas of form, analysis, counterpoint and compositional techniques with opportunities for research in Framework. Since LMusTCL is equivalent to the final year of an undergraduate degree the candidates are supposed to show their understanding through a mix of research, documented experience, case studies and critical reflection. ",
    },
  ];
  const theach = [
    {
      img:"assets/certify/teaching_1.jpeg",
      name: "A.T.C.L - Principles of Instrumental/Vocal Teaching      ",
      para: "This course examines how well you understand the theory behind effective music teaching, and your ability to plan and deliver teaching activities. In its difficulty it is considered like the first year of an undergraduate degree hence, minimal teaching experience is required for you to join the course.",
    },
    {
      img:"assets/certify/teaching_2.jpeg",
      name: "L.T.C.L Instrumental/Vocal Teaching ",
      para: "A step up from ATCL, these classes are designed to help you execute planning and delivering teaching to individuals and small groups at all abilities, taking full control of the teaching environment. Each candidate will show their understanding through a mix of research, documented experience, case studies and critical reflection.  The course compares to the final year of university.",
    },
  ];

  return (
    <div>
      <div className={styles.heading}>
        <div className="container">
          <h1>Certification</h1>
          <p>Stay informed about the most recent music grades repertoire at Nirvana School of Music. Our curriculum covers levels from Initial to Grade 8, after which students can pursue diploma certification</p>
        </div>
      
      </div>
      <section className={styles.Certificate}>
        <div className="container">
          <div className={styles.point}> 
              {/* <p>The course is aimed at individual and small group teaching or large groups (15+ students)</p> */}
              <p>These diplomas share sound design principles, and are divided into two units, both of which must be passed to achieve an overall pass.</p>
          </div>
          <div className={styles.wrp}>
            <div className={styles.clasic}>
              <h2>DIPLOMA IN CLASSICAL MUSIC </h2>
              <div className={styles.cls_wrp}>
                {certify.map((ele, index) => {
                  return (
                    <>
                      <div className={styles.items}>
                        <img src={ele.img} alt="" />
                        <div className={styles.info}>
                          <div className={styles.head}>
                            <h1>0{index + 1}</h1>
                            <h3>{ele.name}</h3>
                          </div>
                          <p>{ele.para}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={styles.clasic}>
              <h2>DIPLOMA IN ROCK AND POP </h2>
              <div className={styles.cls_wrp}>
                {pop.map((ele, index) => {
                  return (
                    <>
                      <div className={styles.items}>
                        <img src={ele.img} alt="" />
                        <div className={styles.info}>
                          <div className={styles.head}>
                            <h1>0{index + 1}</h1>
                            <h3>{ele.name}</h3>
                          </div>
                          <p>{ele.para}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className={styles.clasic}>
              <h2>DIPLOMA IN MUSIC THEORY</h2>
              <div className={styles.cls_wrp}>
                {theory.map((ele, index) => {
                  return (
                    <>
                      <div className={styles.items}>
                        <img src={ele.img} alt="" />
                        <div className={styles.info}>
                          <div className={styles.head}>
                            <h1>0{index + 1}</h1>
                            <h3>{ele.name}</h3>
                          </div>
                          <p>{ele.para}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div className={styles.clasic}>
              <h2>DIPLOMA IN MUSIC TEACHING</h2>
              <div className={styles.cls_wrp}>
                {theach.map((ele, index) => {
                  return (
                    <>
                      <div className={styles.items}>
                        <img src={ele.img} alt="" />
                        <div className={styles.info}>
                          <div className={styles.head}>
                            <h1>0{index + 1}</h1>
                            <h3>{ele.name}</h3>
                          </div>
                          <p>{ele.para}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
